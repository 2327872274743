/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button,
} from "reactstrap";

import {
  FaConciergeBell,
  FaGlobe,
  FaUsers
} from "react-icons/fa";

import {ReactComponent as Logo} from "assets/img/foxy_logo_fill.svg";

function ExamplesNavbar() {
  const handleLogin = (e) => {
    alert("On se prépare un nouveau service pour vous. Des informations de connexion seront prochainement accessibles !");
    setNavbarCollapse(false);
  }

  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="/#top"
            title="Acceuil"
          >
            <Logo />
            <span style={{textTransform:"capitalize"}}>100% Cold Chain</span>
          </NavbarBrand>
          <button
            aria-expanded={!navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            {/* <NavItem>
              <NavLink to="/index" tag={Link}>
                <i className="nc-icon nc-layout-11" /> Components
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink
                href="https://demos.creative-tim.com/paper-kit-react/#/documentation?ref=pkr-examples-navbar"
                target="_blank"
              >
                <i className="nc-icon nc-book-bookmark" /> Documentation
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://twitter.com/broufox"
                target="_blank"
                title="Follow us on Twitter"
              >
                <i className="fa fa-twitter" />
                <p className="d-lg-none">Twitter</p>
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="/#value"
                title="Valeurs"
                onClick={()=>setNavbarCollapse(false)}
              >
                <i className="fa"><FaGlobe style={{margin:"0 0 0.136em 0"}}/></i>
                <p className="d-lg-none" style={{textTransform:"none"}}>Valeurs</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="/#partenariat"
                title="Vos partenaires"
                onClick={()=>setNavbarCollapse(false)}
              >
                <i className="fa"><FaUsers style={{margin:"0 0 0.136em 0"}}/></i>
                <p className="d-lg-none" style={{textTransform:"none"}}>Vos partenaires</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="/#contact"
                title="Contactez-nous"
                onClick={()=>setNavbarCollapse(false)}
              >
                <i className="fa"><FaConciergeBell style={{margin:"0 0 0.136em 0"}}/></i>
                <p className="d-lg-none" style={{textTransform:"none"}}>Contactez-nous</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.instagram.com/100coldchain"
                target="_blank"
                title="Suivez-nous sur Instagram"
                // onClick={toggleNavbarCollapse}
                // onClick={()=>setNavbarCollapse(false)}
              >
                <i className="fa fa-instagram" />
                <p className="d-lg-none" style={{textTransform:"capitalize"}}>Instagram</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.facebook.com/100coldchain"
                target="_blank"
                title="Likez nous sur Facebook"
                // onClick={toggleNavbarCollapse}
                // onClick={()=>setNavbarCollapse(false)}
              >
                <i className="fa fa-facebook-square" /> 
                <p className="d-lg-none" style={{textTransform:"capitalize"}}>Facebook</p>
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.github.com/CreativeTimOfficial?ref=creativetim"
                target="_blank"
                title="Star on GitHub"
              >
                <i className="fa fa-github" />
                <p className="d-lg-none">GitHub</p>
              </NavLink>
            </NavItem> */}
            <NavItem>
              <Button
                className="btn-round"
                color="info"
                href="/#top"
                onClick={handleLogin}
              >
                {/* <i className="nc-icon nc-spaceship"></i> */}
                {/* <Logo 
                  style={
                    {
                      height: "1.5em"
                    }
                  } 
                /> */}
                Connexion
              </Button>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default ExamplesNavbar;
