/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { db } from "../../firebase";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

import {
  FaRegSnowflake,
  FaShippingFast,
  FaUsers,
  FaThumbsUp,
  FaConciergeBell,
  FaGlobe,
} from "react-icons/fa";

function LandingPage(props) {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);

  const handleSubmit = (e) => {
    // alert("it works!");
    e.preventDefault();
    if (!email) {
      alert("Une adresse mail est requis");
      return;
    }
    const emailCheck = emailValidation();
    if (!emailCheck) {
      db.collection("/messages").add({
        name: name,
        email: email,
        message: message,
        timeStamp: new Date(),
        siteName: "100coldchain.com",
      });
      setName("");
      setEmail("");
      setMessage("");
      alert("Merci, votre message a bien été envoyé !");
    } else {
      alert(emailCheck);
    }
  };

  const emailValidation = () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
      return null;
    }
    if (email.trim() === "") {
      return "Une adresse mail est requis";
    }
    return "Veuillez saisir une adresse mail valide";
  };

  document.documentElement.classList.remove("nav-open");

  React.useEffect(() => {
    // db.doc("/users/TLOceCl66beFtFlegj8X").onSnapshot((user) => {
    //   setUserName(user.data().name);
    // });

    // const dateLocale = new Date().toLocaleTimeString();
    // db.collection("/messages").add(
    //   {
    //     name: "Dave",
    //     email: "dave@company.com",
    //     message: "test: " + dateLocale
    //   }
    // );

    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  return (
    <>
      <div id="top"></div>
      <ExamplesNavbar />
      <LandingPageHeader />
      <div id="value"></div>
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">
                  <FaGlobe style={{ margin: "0 0.4em 0.18em 0" }} />
                  Valeurs
                </h2>
                {/* <h2 className="title">Valeurs</h2> */}
                <h5 className="description">au coeur de notre ADN</h5>
                <br />
                {/* <Button
                  className="btn-round"
                  color="info"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  See Details
                </Button> */}
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <FaThumbsUp />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Excellence</h4>
                    <p className="description">
                      les meilleurs bières, fraîchement brassées avec une chaîne
                      logistique du froid pour leur conserver la fraîcheur et la
                      qualité...
                    </p>
                    {/* <Button className="btn-link" color="info" href="#pablo">
                      See more
                    </Button> */}
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    {/* <i className="nc-icon nc-button-power" /> */}
                    <FaRegSnowflake />
                  </div>
                  <div className="description">
                    <h4 className="info-title">100% Cold Chain</h4>
                    <p>
                      vos bières sont expédiées à 3ºC depuis la chambre froide
                      au royaume-uni jusqu'a chez vous...
                    </p>
                    {/* <Button className="btn-link" color="info" href="#pablo">
                      See more
                    </Button> */}
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <FaShippingFast />
                  </div>
                  <div className="description">
                    <h4 className="info-title">En direct du UK</h4>
                    <p>
                      la distance et le delai le plus court entre la mise en
                      canette et tous les bars et caves dans l'Hexagone...
                    </p>
                    {/* <Button className="btn-link" color="info" href="#pablo">
                      See more
                    </Button> */}
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <FaUsers />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Partenariat</h4>
                    <p>
                      en travaillant ensemble, les brasseries, vous et nous, on
                      parvient au meilleur rapport qualité prix...
                    </p>
                    {/* <Button className="btn-link" color="info" href="#pablo">
                      See more
                    </Button> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div id="partenariat"></div>
        <div className="section section-dark text-center">
          <Container>
            <h2 className="title">
              <FaUsers style={{ margin: "0 0.4em 0.23em 0" }} />
              Vos partenaires
            </h2>
            {/* <h2 className="title">Les partenaires</h2> */}
            <Row>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/cw-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Cloudwater</CardTitle>
                        <h6 className="card-category">Manchester</h6>
                      </div>
                    </a>
                    {/* <p className="card-description text-center">
                      Teamwork is so important that it is virtually impossible
                      for you to reach the heights of your capabilities or make
                      the money that you want without becoming very good at it.
                    </p> */}
                  </CardBody>
                  {/* <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-google-plus" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter> */}
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/nm-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Northern Monk</CardTitle>
                        <h6 className="card-category">Leeds</h6>
                      </div>
                    </a>
                    {/* <p className="card-description text-center">
                      A group becomes a team when each member is sure enough of
                      himself and his contribution to praise the skill of the
                      others. No one can whistle a symphony. It takes an
                      orchestra to play it.
                    </p> */}
                  </CardBody>
                  {/* <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-google-plus" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter> */}
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/ve-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Verdant</CardTitle>
                        <h6 className="card-category">Falmouth</h6>
                      </div>
                    </a>
                    {/* <p className="card-description text-center">
                      The strength of the team is each individual member. The
                      strength of each member is the team. If you can laugh
                      together, you can work together, silence isn’t golden,
                      it’s deadly.
                    </p> */}
                  </CardBody>
                  {/* <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-google-plus" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter> */}
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/ss-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Sureshot</CardTitle>
                        <h6 className="card-category">Manchester</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/vc-fb-logo.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Vault City</CardTitle>
                        <h6 className="card-category">Edinburgh</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
      
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/de-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">DEYA</CardTitle>
                        <h6 className="card-category">Cheltenham</h6>
                      </div>
                    </a>
                    {/* <p className="card-description text-center">
                        A group becomes a team when each member is sure enough of
                        himself and his contribution to praise the skill of the
                        others. No one can whistle a symphony. It takes an
                        orchestra to play it.
                      </p> */}
                  </CardBody>
                  <CardFooter className="text-center">
                    {/* <Button
                        className="btn-just-icon btn-neutral"
                        color="link"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-twitter" />
                      </Button>
                      <Button
                        className="btn-just-icon btn-neutral ml-1"
                        color="link"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-google-plus" />
                      </Button>
                      <Button
                        className="btn-just-icon btn-neutral ml-1"
                        color="link"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-linkedin" />
                      </Button> */}
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/pb-fb-logo-2021-06-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Polly's Brew</CardTitle>
                        <h6 className="card-category">Pays de Galles</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/bk-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Beak</CardTitle>
                        <h6 className="card-category">Lewes</h6>
                      </div>
                    </a>
                  </CardBody>
                  <CardFooter className="text-center"></CardFooter>
                </Card>
              </Col>
              
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/pi-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Pomona Island</CardTitle>
                        <h6 className="card-category">Manchester</h6>
                      </div>
                    </a>
                    {/* <p className="card-description text-center">
                        Teamwork is so important that it is virtually impossible
                        for you to reach the heights of your capabilities or make
                        the money that you want without becoming very good at it.
                      </p> */}
                  </CardBody>
                  {/* <CardFooter className="text-center">
                      <Button
                        className="btn-just-icon btn-neutral"
                        color="link"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-twitter" />
                      </Button>
                      <Button
                        className="btn-just-icon btn-neutral ml-1"
                        color="link"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-google-plus" />
                      </Button>
                      <Button
                        className="btn-just-icon btn-neutral ml-1"
                        color="link"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-linkedin" />
                      </Button>
                    </CardFooter> */}
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/nb-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">North</CardTitle>
                        <h6 className="card-category">Leeds</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/bs-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Burning Sky</CardTitle>
                        <h6 className="card-category">Firle</h6>
                      </div>
                    </a>
                  </CardBody>
                  <CardFooter className="text-center"></CardFooter>
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/lh-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Left Handed Giant</CardTitle>
                        <h6 className="card-category">Bristol</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/nr-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Neon Raptor</CardTitle>
                        <h6 className="card-category">Nottingham</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/bx-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Buxton</CardTitle>
                        <h6 className="card-category">Buxton</h6>
                      </div>
                    </a>
                  </CardBody>
                  <CardFooter className="text-center"></CardFooter>
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/lg-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Lost and Grounded</CardTitle>
                        <h6 className="card-category">Bristol</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/ar-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Arbor</CardTitle>
                        <h6 className="card-category">Bristol</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/pd-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Pressure Drop</CardTitle>
                        <h6 className="card-category">London</h6>
                      </div>
                    </a>
                  </CardBody>
                  <CardFooter className="text-center"></CardFooter>
                </Card>
              </Col>                    
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/tk-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Track</CardTitle>
                        <h6 className="card-category">Manchester</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/ho-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Hogan's Cider</CardTitle>
                        <h6 className="card-category">Alcester</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              {/* <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/un-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Unity</CardTitle>
                        <h6 className="card-category">Southampton</h6>
                      </div>
                    </a>
                  </CardBody>
                  <CardFooter className="text-center"></CardFooter>
                </Card>
              </Col> */}
              {/* <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/rb-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Redchurch</CardTitle>
                        <h6 className="card-category">London</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col> */}
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/ov-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Overtone</CardTitle>
                        <h6 className="card-category">Glasgow</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              {/* <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/gh-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">GlassHouse</CardTitle>
                        <h6 className="card-category">Birmingham</h6>
                      </div>
                    </a>
                  </CardBody>
                  <CardFooter className="text-center"></CardFooter>
                </Card>
              </Col> */}
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/bc-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Boxcar</CardTitle>
                        <h6 className="card-category">London</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={
                          require("assets/img/faces/cpcc-logo-01.jpg").default
                        }
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">100% Cold Chain</CardTitle>
                        <h6 className="card-category">Leeds GB</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={
                          require("assets/img/faces/cpcc-logo-01.jpg").default
                        }
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">100% Cold Chain</CardTitle>
                        <h6 className="card-category">Paris FR</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={
                          require("assets/img/faces/cpcc-logo-01.jpg").default
                        }
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">100% Cold Chain</CardTitle>
                        <h6 className="card-category">Belfast NI</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              {/* <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/al-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Alvinne</CardTitle>
                        <h6 className="card-category">Belgique</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col> */}
              {/* <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/ms-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Mont Salève</CardTitle>
                        <h6 className="card-category">Haute Savoie</h6>
                      </div>
                    </a>
                  </CardBody>
                  <CardFooter className="text-center"></CardFooter>
                </Card>
              </Col> */}
              {/* <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/ma-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Marble</CardTitle>
                        <h6 className="card-category">Manchester</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col> */}
              {/* <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={
                          require("assets/img/faces/cpcc-logo-01.jpg").default
                        }
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">100% Cold Chain</CardTitle>
                        <h6 className="card-category">Belfast NI</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col> */}
              {/* <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={
                          require("assets/img/faces/cpcc-logo-01.jpg").default
                        }
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">100% Cold Chain</CardTitle>
                        <h6 className="card-category">Bristol GB</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col> */}
              {/* <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={
                          require("assets/img/faces/cpcc-logo-01.jpg").default
                        }
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">100% Cold Chain</CardTitle>
                        <h6 className="card-category">Leeds GB</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={
                          require("assets/img/faces/cpcc-logo-01.jpg").default
                        }
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">100% Cold Chain</CardTitle>
                        <h6 className="card-category">Paris FR</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col> */}
              {/* <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/bm-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Burnt Mill</CardTitle>
                        <h6 className="card-category">Suffolk</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/mo-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Moor Beer</CardTitle>
                        <h6 className="card-category">Bristol</h6>
                      </div>
                    </a>
                  </CardBody>
                  <CardFooter className="text-center"></CardFooter>
                </Card>
              </Col>
              <Col md="4" xl="2">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/ma-logo-01.jpg").default}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Marble</CardTitle>
                        <h6 className="card-category">Manchester</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col> */}
            </Row>
          </Container>
        </div>
        <div className="section landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 id="contact" className="text-center">
                  <FaConciergeBell style={{ margin: "0 0.4em 0.24em 0" }} />
                  Contactez-nous
                </h2>
                <Form
                  onSubmit={handleSubmit}
                  className="contact-form"
                  autoComplete="off"
                >
                  <Row>
                    <Col md="6">
                      <label>Nom</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Nom"
                          type="text"
                          value={name ? name : ""}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label>Mail</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Mail *"
                          type="text"
                          value={email ? email : ""}
                          onChange={(e) => {
                            setEmail(e.target.value.trim());
                          }}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <label>Message</label>
                  <Input
                    placeholder="Nous vous remercions de votre visite. Veuillez nous envoyer un message..."
                    type="textarea"
                    rows="4"
                    value={message ? message : ""}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button
                        type="submit"
                        className="btn-fill"
                        color="info"
                        size="lg"
                        style={{ textTransform: "capitalize" }}
                      >
                        Envoyer
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <DemoFooter />
    </>
  );
}

export default LandingPage;
