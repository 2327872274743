/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";

import { SiInstagram, SiFacebook } from 'react-icons/si';

function DemoFooter() {
  const style = {fontSize: 'xx-large'};

  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              {/* <li>
                <a
                  href="/#"
                  target="_blank"
                >
                  <SiInstagram style={style}/>
                </a>
              </li>
              <li>
                <a
                  href="/#"
                  target="_blank"
                >
                 <SiFacebook style={style}/>
                </a>
              </li> */}
              {/* <li>
                <a
                  href="https://www.creative-tim.com/license?ref=pkr-footer"
                  target="_blank"
                >
                  Licenses
                </a>
              </li> */}
            </ul>
          </nav>
          <div className="credits ml-auto">
            {/* <span className="copyright">
              © {new Date().getFullYear()}, made with{" "}
              <i className="fa fa-heart heart" /> by Creative Tim
            </span> */}
            <span className="copyright">
              © 100% Cold Chain {new Date().getFullYear()}
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
